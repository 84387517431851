import React, { useRef, useEffect, useContext } from 'react';
import styled, { keyframes } from 'styled-components';
import { Link } from 'gatsby';

import { GlobalStateContext } from '../storage/globalContextProvider';

import variables from '../styles/variables';
import Layout from '../components/layout';
import { PageTitle } from '../components/titles';

const JumpIn = keyframes`
  from {
    transform: scale(0) translate(-50%, -200%);
    opacity: 0;  
  }
  to {
    transform: scale(1) translate(-50%, 0);
    opacity: 1; 
  }
`;

const FadeIn = keyframes`
  from { opacity: 0;  }
  to { opacity: 1; }
`;

const HomePageContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  & > * {
    animation: ${props => (props.animations ? 'auto' : 'none !important')};
  }
`;

const IntroductionContainer = styled.div`
  display: block;
  position: relative;
  top: calc(3vh);
  width: 530px;
  margin: 0 auto;
  padding-top: 1rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
  padding-left: 2rem;
  background-color: ${props => props.theme.colorPrimaryDark}cc;
  box-shadow: 0 0 40px ${props => props.theme.colorPrimaryDark};
  text-shadow: 2px 2px 0 ${props => props.theme.colorTextDark};
  border: 1px solid ${props => props.theme.colorPrimaryLight};
  border-radius: 10px;
  z-index: 1;
  animation: ${FadeIn} 2s linear 1;
  animation-delay: 5s;
  animation-fill-mode: backwards;

  @media only screen and (max-width: ${variables.screenWidth}) {
    top: ${variables.menuHeightMobile};
    width: 100vw;
    border: none;
    border-radius: 0px;
  }

  a:link,
  a:visited,
  a:hover,
  a:active {
    color: ${props => props.theme.colorText};
  }
`;

const Hi = styled(PageTitle)`
  margin-bottom: 0;

  @media only screen and (max-width: ${variables.screenWidth}) {
    font-size: 6vh;
  }
`;

const IntroText = styled.p`
  font-size: 2rem;

  @media only screen and (max-width: ${variables.screenWidth}) {
    font-size: 2.5vh;
  }
`;

const TitleContainer = styled.div`
  display: block;
  position: absolute;
  left: 50vw;
  bottom: 4vmax;
  width: 530px;
  height: 387px;
  transform: translateX(-50%);
  font-family: ${props => props.theme.fontSecondary};
  animation: ${JumpIn} 2s linear 1;
  animation-fill-mode: backwards;

  @media only screen and (max-width: ${variables.screenWidth}) {
    width: 90vmin;
    height: 65.71vmin;
  }
`;

const TriangleBase = styled.div`
  position: absolute;
  top: 6.45%;
  right: 0;
  width: 79.24%;
  height: 93.02%;
  z-index: -2;
`;

const Triangle1 = styled(TriangleBase)`
  background: linear-gradient(to bottom, #00ffff, #00ff99);
  clip-path: polygon(
    10% 5%,
    8% 0%,
    0% 100%,
    100% 56%,
    8% 0%,
    10% 5%,
    93.5% 55.5%,
    2.5% 96%,
    10% 5%
  );
`;

const Triangle2 = styled(TriangleBase)`
  background: linear-gradient(to bottom, #ff00dedd, #3300ffdd);
  clip-path: polygon(10% 5%, 2.5% 96%, 93.5% 55.5%);
`;

const TitleBase = styled.div`
  display: inline-block;
  position: absolute;
  font-size: 10rem;
  line-height: 1;
  overflow: visible;
  background: linear-gradient(
    to bottom,
    #ff00ff 10%,
    #ffffff 47%,
    #000a56 47%,
    #000a56 51%,
    #001efc 85%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  &::before {
    content: '${props => props.content}';
    position: absolute;
    padding-right: 5px;
    background: -webkit-linear-gradient(
      -90deg,
      #f7f1ff 26%,
      #fd5aff 38%,
      #232386 71%,
      #eeeeff 85%
    );
    -webkit-background-clip: text;
    -webkit-text-stroke: 8px transparent;
    z-index: -1;
  }

  &::after {
    content: '${props => props.content}';
    position: absolute;
    transform: translateX(-100%);
    text-shadow: 5px 5px 1px #0000ff;
    z-index: -2;
  }

  @media only screen and (max-width: ${variables.screenWidth}) {
    font-size: 16.87vmin;

    &::before {
      -webkit-text-stroke: 1.4vw transparent;
    }

    &::after {
      text-shadow: 0.94vw 0.94vw 1px #0000ff;
    }
  }
`;

const TitleTop = styled(TitleBase)`
  top: 115px;
  left: 133px;

  @media only screen and (max-width: ${variables.screenWidth}) {
    top: 30%;
    left: 25.5%;
  }
`;

const TitleBottom = styled(TitleBase)`
  top: 215px;
  left: 124px;

  @media only screen and (max-width: ${variables.screenWidth}) {
    top: 55.55%;
    left: 24%;
  }
`;

const DotCom = styled.div`
  position: absolute;
  top: 260px;
  left: 340px;
  color: #ffddee;
  font-size: 7rem;
  font-family: ${props => props.theme.fontTertiary};
  transform: scaleX(1.2);
  text-shadow: 0 0 12px #ff0000;

  &::before {
    content: '${props => props.content}';
    position: absolute;
  }

  &::after {
    content: '${props => props.content}';
    transform: translateX(-100%);
    position: absolute;
  }

  @media only screen and (max-width: ${variables.screenWidth}) {
    font-size: 11.81vmin;
    top: 67.18%;
    left: 64.15%;
    text-shadow: 0 0 2.54vw #ff0000;
  }
`;

const Gif = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 28.3%;
  image-rendering: pixelated;
  z-index: 1;

  @-moz-document url-prefix() {
    image-rendering: crisp-edges;
  }
`;

const GifIntro = styled(Gif)`
  -webkit-animation: ${FadeIn} 2.5s ease-in-out 1;
  animation: ${FadeIn} 2.5s ease-in-out 1;
  animation-delay: 2.5s;
  animation-fill-mode: backwards;
`;

const GifLoop = styled(Gif)``;

const HomePage = () => {
  const gifIntro = useRef(null);
  const gifLoop = useRef(null);
  const state = useContext(GlobalStateContext);

  useEffect(() => {
    // Adding random query to src to force
    // loading instead of using cached images.
    const timer1 = setTimeout(() => {
      gifIntro.current.src = `../img/intro.gif?a= + ${Math.random()}`;
    }, 2500);

    const timer2 = setTimeout(() => {
      gifLoop.current.src = '../img/loop.gif';
    }, 7300);

    const timer3 = setTimeout(() => {
      gifIntro.current.style.display = 'none';
    }, 7800);

    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
      clearTimeout(timer3);
    };
  });

  return (
    <>
      <HomePageContainer animations={state.animations}>
        <IntroductionContainer>
          <Hi>Hi, I'm Willem.</Hi>
          <IntroText>
            This is my personal website where I <Link to={'/blog'}>blog</Link>{' '}
            and <Link to={'/projects'}>showcase my projects</Link>. I'm really
            into software development and computers, and as you might've
            guessed, I've got a thing for synthwave. &#128521; Feel free to take
            a look around and don't hesitate to{' '}
            <Link to={'/contact'}>get in touch</Link>.
          </IntroText>
        </IntroductionContainer>
        <TitleContainer>
          <GifIntro ref={gifIntro} src="../img/placeholder.gif" />
          <GifLoop ref={gifLoop} src="../img/placeholder.gif" />
          <Triangle1 />
          <Triangle2 />
          <TitleTop content={'WILLEM'}>WILLEM</TitleTop>
          <TitleBottom content={'DEEN'}>DEEN</TitleBottom>
          <DotCom content={'.COM'}>.COM</DotCom>
        </TitleContainer>
      </HomePageContainer>
    </>
  );
};

HomePage.Layout = Layout;

export default HomePage;
