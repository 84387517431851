import styled from 'styled-components';

const PageTitle = styled.h1`
  margin-bottom: 2rem;
  font-size: 4.5rem;
  font-family: ${props => props.theme.fontSecondary};
  text-shadow: 1px 1px ${props => props.theme.colorPrimaryLight},
    5px 5px ${props => props.theme.colorPrimaryDark};
`;

const SectionTitle = styled.h2`
  font-size: 3rem;
  margin-bottom: 1rem;
`;

export { PageTitle, SectionTitle };
